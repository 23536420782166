exports.components = {
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-household-groceries-tsx": () => import("./../../../src/pages/household/groceries.tsx" /* webpackChunkName: "component---src-pages-household-groceries-tsx" */),
  "component---src-pages-household-index-tsx": () => import("./../../../src/pages/household/index.tsx" /* webpackChunkName: "component---src-pages-household-index-tsx" */),
  "component---src-pages-household-preferences-tsx": () => import("./../../../src/pages/household/preferences.tsx" /* webpackChunkName: "component---src-pages-household-preferences-tsx" */),
  "component---src-pages-household-profiles-tsx": () => import("./../../../src/pages/household/profiles.tsx" /* webpackChunkName: "component---src-pages-household-profiles-tsx" */),
  "component---src-pages-household-shipments-tsx": () => import("./../../../src/pages/household/shipments.tsx" /* webpackChunkName: "component---src-pages-household-shipments-tsx" */),
  "component---src-pages-household-wallet-tsx": () => import("./../../../src/pages/household/wallet.tsx" /* webpackChunkName: "component---src-pages-household-wallet-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plants-tsx": () => import("./../../../src/pages/plants.tsx" /* webpackChunkName: "component---src-pages-plants-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-recipebox-tsx": () => import("./../../../src/pages/recipebox.tsx" /* webpackChunkName: "component---src-pages-recipebox-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-pages-story-tsx": () => import("./../../../src/pages/story.tsx" /* webpackChunkName: "component---src-pages-story-tsx" */),
  "component---src-pages-vegetables-tsx": () => import("./../../../src/pages/vegetables.tsx" /* webpackChunkName: "component---src-pages-vegetables-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-recipe-amp-tsx": () => import("./../../../src/templates/recipeAmp.tsx" /* webpackChunkName: "component---src-templates-recipe-amp-tsx" */),
  "component---src-templates-recipe-meal-type-tsx": () => import("./../../../src/templates/recipeMealType.tsx" /* webpackChunkName: "component---src-templates-recipe-meal-type-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */)
}

