export enum EMAIL_SEND_FREQUENCY {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum EMAIL_PREFERENCES {
  TRY_UPDATE_RECIPE_EMAIL_PREFERENCES = 'TRY_UPDATE_RECIPES_EMAIL_PREFERENCES',
  UPDATING_RECIPE_EMAIL_PREFERENCES = "UPDATING_RECIPE_EMAIL_PREFERENCES",
  UPDATED_RECIPES_EMAIL_PREFERENCES_SUCCESS = 'UPDATED_RECIPE_EMAIL_PREFERENCES_SUCCESS',
  UPDATED_RECIPE_EMAIL_PREFERENCES_FAILURE = 'UPDATED_RECIPE_EMAIL_PREFERENCES_FAILURE',

  TRY_UPDATE_ARTICLE_EMAIL_PREFERENCES = 'TRY_UPDATE_ARTICLE_EMAIL_PREFERENCES',
  UPDATING_ARTICLE_EMAIL_PREFERENCES = 'UPATING_ARTICLE_EMAIL_PREFERENCES',
  UPDATED_ARTICLE_EMAIL_PREFERENCES_SUCCESS = 'UPDATED_ARTICLE_EMAIL_PREFERENCES_SUCCESS',
  UPDATED_ARTICLE_EMAIL_PREFERENCES_FAILURE = 'UPDATED_ARTICLE_EMAIL_PREFERENCES_FAILURE',

  TRY_UPDATE_ROUNDUP_EMAIL_PREFERENCES = 'TRY_UPDATE_ROUNDUP_EMAIL_PREFERENCES',
  UPDATING_ROUNDUP_EMAIL_PREFERENCES = 'UPDATING_ROUNDUP_EMAIL_PREFERENCES',
  UPDATED_ROUNDUP_EMAIL_PREFERENCES_SUCCESS = 'UPDATED_ROUNDUP_EMAIL_PREFERENCES_SUCCESS',
  UPDATED_ROUNDUP_EMAIL_PREFERENCES_FAILURE = 'UPDATED_ROUNDUP_EMAIL_PREFERENCES_FAILURE'
}
