export enum RATINGS {
  TRY_ADD_RECIPE_RATING = 'TRY_ADD_RECIPE_RATING',
  ADDING_RECIPE_RATING = 'ADDING_RECIPE_RATING',
  ADD_RECIPE_RATING_SUCCESS = 'ADD_RECIPE_RATING_SUCCESS',
  ADD_RECIPE_RATING_FAILURE = 'ADD_RECIPE_RATING_FAILURE',

  TRY_FETCH_ALL_RECIPE_RATINGS = 'TRY_FETCH_ALL_RECIPE_RATINGS',
  FETCHING_ALL_RECIPE_RATINGS = 'FETCHING_ALL_RECIPE_RATINGS',
  FETCH_ALL_RECIPE_RATINGS_SUCCESS = 'FETCH_ALL_RECIPE_RATINGS_SUCCESS',
  FETCH_ALL_RECIPE_RATINGS_FAILURE = 'FETCH_ALL_RECIPE_RATINGS_FAILURE',

  TRY_ADD_ARTICLE_RATING = 'TRY_ADD_ARTICLE_RATING',
  ADDING_ARTICLE_RATING = 'ADDING_ARTICLE_RATING',
  ADD_ARTICLE_RATING_SUCCESS = 'ADD_ARTICLE_RATING_SUCCESS',
  ADD_ARTICLE_RATING_FAILURE = 'ADD_ARTICLE_RATING_FAILURE',

  TRY_FETCH_ALL_ARTICLE_RATINGS = 'TRY_FETCH_ALL_ARTICLE_RATINGS',
  FETCHING_ALL_ARTICLE_RATINGS = 'FETCH_ALL_ARTICLE_RATINGS',
  FETCH_ALL_ARTICLE_RATINGS_SUCCESS = 'FETCH_ALL_ARTICLE_RATINGS_SUCCESS',
  FETCH_ALL_ARTICLE_RATINGS_FAILURE = 'FETCH_ALL_ARTICLE_RATINGS_FAILURE',
}
