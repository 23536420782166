export enum RECIPEBOX {
  TRY_FETCH_RECIPEBOX = 'TRY_FETCH_RECIPEBOX',
  FETCHING_RECIPEBOX = 'FETCHING_RECIPEBOX',
  FETCHED_RECIPEBOX_SUCCESS = 'FETCHED_RECIPEBOX_SUCCESS',
  FETCHED_RECIPEBOX_FAILURE = 'FETCH_RECIPEBOX_FAILURE',

  TRY_ADD_RECIPE = 'TRY_ADD_RECIPE',
  ADDING_RECIPE = 'ADDING_RECIPE',
  ADD_RECIPE_SUCCESS = 'ADD_RECIPE_SUCCESS',
  ADD_RECIPE_FAILURE = 'ADD_RECIPE_FAILRE',
  TRY_DELETE_RECIPE = 'TRY_DELETE_RECIPE',
  DELETING_RECIPE = 'DELETING_RECIPE',
  DELETE_RECIPE_SUCCESS = 'DELETE_RECIPE_SUCCESS',
  DELETE_RECIPE_FAILURE = 'DETEL_RECIPE_FAILURE',

  TRY_ADD_ARTICLE = 'TRY_ADD_ARTICLE',
  ADDING_ARTICLE = 'ADDING_ARTICLE',
  ADD_ARTICLE_SUCCESS = 'ADD_ARTICLE_SUCCESS',
  ADD_ARTICLE_FAILURE = 'ADD_ARTICLE_FAILURE',
  TRY_DELETE_ARTICLE = 'TRY_DELETE_ARTICLE',
  DELETING_ARTICLE = 'DELETING_ARTICLE',
  DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS',
  DELETE_ARTICLE_FAILURE = 'DELETE_ARTICLE_FAILURE',

  TRY_ADD_RECIPE_VIEW = 'TRY_ADD_RECIPE_VIEW',
  ADDING_ADD_RECIPE_VIEW = 'ADDING_ADD_RECIPE_VIEW',
  ADD_RECIPE_VIEW_SUCCESS = 'ADD_RECIPE_VIEW_SUCCESS',
  ADD_RECIPE_VIEW_FAILURE = 'ADD_RECIPE_VIEW_FAILURE',

  TRY_ADD_RECIPE_COOKED = 'TRY_ADD_RECIPE_COOKED',
  ADDING_ADD_RECIPE_COOKED = 'ADDING_ADD_RECIPE_COOKED',
  ADD_RECIPE_COOKED_SUCCESS = 'ADD_RECIPE_COOKED_SUCCESS',
  ADD_RECIPE_COOKED_FAILURE = 'ADD_RECIPE_COOKED_FAILURE',

  TRY_ADD_ARTICLE_VIEW = 'TRY_ADD_ARTICLE_VIEW',
  ADDING_ADD_ARTICLE_VIEW = 'ADDING_ADD_ARTICLE_VIEW',
  ADD_ARTICLE_VIEW_SUCCESS = 'ADD_ARTICLE_VIEW_SUCCESS',
  ADD_ARTICLE_VIEW_FAILURE = 'ADD_ARTICLE_VIEW_FAILURE',

  TRY_FETCH_RECENTLY_ADDED_RECIPES_RECOMMENDATIONS = 'TRY_FETCH_RECENTLY_ADDED_RECIPES_RECOMMENDATIONS',
  FETCHING_RECENTLY_ADDDED_RECPES_RECOMMENDATIONS = 'FETCHING_RECENTLY_ADDDED_RECPES_RECOMMENDATIONS',
  FETCH_RECENTLY_ADDED_RECIPES_RECOMMENDATIONS_SUCCESS = 'FETCH_RECENTLY_ADDED_RECIPES_RECOMMENDATIONS_SUCCESS',
  FETCH_RECENTLY_ADDED_RECIPES_RECOMMENDATIONS_FAILURE = 'FETCH_RECENTLY_ADDED_RECIPES_RECOMMENDATIONS_FAILURE',

  TRY_FETCH_RECENTLY_VIEWED_RECIPES_RECOMMENDATIONS = 'TRY_FETCH_RECENTLY_VIEWED_RECIPES_RECOMMENDATIONS',
  FETCHING_RECENTLY_VIEWED_RECIPES_RECOMMENDATIONS = 'FETCHING_RECENTLY_VIEWED_RECIPES_RECOMMENDATIONS',
  FETCH_RECENTLY_VIEWED_RECIPES_RECOMMENDATIONS_SUCCESS = 'FETCH_RECENTLY_VIEWED_RECIPES_RECOMMENDATIONS_SUCCESS',
  FETCH_RECENTLY_VIEWED_RECIPES_RECOMMENDATIONS_FAILURE = 'FETCH_RECENTLY_VIEWED_RECIPES_RECOMMENDATIONS_FAILURE',

  TRY_FETCH_MOST_COOKED_RECIPES_RECOMMENDATIONS = 'TRY_FETCH_MOST_COOKED_RECIPES_RECOMMENDATIONS',
  FETCHING_MOSTED_COOKED_RECIPES_RECOMMENDATIONS = 'FETCHING_MOSTED_COOKED_RECIPES_RECOMMENDATIONS',
  FETCH_MOST_COOKED_RECIPES_RECOMMENDATIONS_SUCCESS = 'FETCH_MOST_COOKED_RECIPES_RECOMMENDATIONS_SUCCESS',
  FETCH_MOKST_COOKED_RECIPES_RECOMMENDATIONS_FAILURE = 'FETCH_MOKST_COOKED_RECIPES_RECOMMENDATIONS_FAILURE',

  TRY_FETCH_RECENTLY_ADDED_ARTICLES_RECOMMENDATIONS = 'TRY_FETCH_RECENTLY_ADDED_ARTICLES_RECOMMENDATIONS',
  FETCHING_RECENTLY_ADDDED_ARTICLES_RECOMMENDATIONS = 'FETCHING_RECENTLY_ADDDED_ARTICLES_RECOMMENDATIONS',
  FETCH_RECENTLY_ADDED_ARTICLES_RECOMMENDATIONS_SUCCESS = 'FETCH_RECENTLY_ADDED_ARTICLES_RECOMMENDATIONS_SUCCESS',
  FETCH_RECENTLY_ADDED_ARTICLES_RECOMMENDATIONS_FAILURE = 'FETCH_RECENTLY_ADDED_ARTICLES_RECOMMENDATIONS_FAILURE',

  TRY_FETCH_RECENTLY_VIEWED_ARTICLES_RECOMMENDATIONS = 'TRY_FETCH_RECENTLY_VIEWED_ARTICLES_RECOMMENDATIONS',
  FETCHING_RECENTLY_VIEWED_ARTICLES_RECOMMENDATIONS = 'FETCHING_RECENTLY_VIEWED_ARTICLES_RECOMMENDATIONS',
  FETCH_RECENTLY_VIEWED_ARTICLES_RECOMMENDATIONS_SUCCESS = 'FETCH_RECENTLY_VIEWED_ARTICLES_RECOMMENDATIONS_SUCCESS',
  FETCH_RECENTLY_VIEWED_ARTICLES_RECOMMENDATIONS_FAILURE = 'FETCH_RECENTLY_VIEWED_ARTICLES_RECOMMENDATIONS_FAILURE',
}
