export enum LOGIN_STEPS {
  DEFAULT = 'DEFAULT',
  SUBMIT_LOGIN = 'SUBMIT_LOGIN',
  LOGGING_IN = 'LOGGING_IN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  RELOGIN = 'RELOGIN',
  TRY_LOCAL_STORAGE_LOGIN = 'TRY_LOCAL_STORAGE_LOGIN',
  LOCAL_STORAGE_LOGIN_SUCCESS = 'LOCAL_STORAGE_LOGIN_SUCCESS',
  LOCAL_STORAGE_LOGIN_FAILURE = 'LOCAL_STORAGE_LOGIN_FAILURE',
}

export enum LOGOUT {
  TRY_LOGOUT = 'TRY_LOGOUT',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',
}
