export enum HEADER_ACTION_TYPES {
  FETCH = 'FETCH',
  FAMILY_SELECTED = 'FAMILY_SELECTED',
  CATEGORY_SELECTED = 'CATEGORY_SELECTED',
  CATEGORY_DETAILS_FETCHED = 'CATEGORY_DETAILS_FETCHED',
  SHOW_SEARCH = 'SHOW_SEARCH',
  HIDE_SEARCH = 'HIDE_SEARCH',
  SHOW_PROFILE_LOGIN = 'SHOW_PROFILE_LOGIN',
  HIDE_PROFILE_LOGIN = 'HIDE_PROFILE_LOGIN',
  SHOW_PROFILE_QUESTIONS = 'SHOW_PROFILE_QUESTIONS',
}

export enum HEADER_NOTIFICATION_TYPES {
  SHOW_RECIPES_BOX_LOGIN_REGISTER_NOTIFICATION = 'SHOW_RECIPES_BOX_LOGIN_REGISTER_NOTIFICATION'
}