export enum CREATE_HOUSEHOLD_SURVEY_STEP {
  STEP1 = 'WELCOME',
  STEP2 = 'PLAN',
  STEP3 = 'HOUSEHOLD',
}

export enum CREATE_HOUSEHOLD_FROM {
  SURVEY = 'SURVEY',
  NEWSLETTER = 'NEWSLETTER,',
}

export enum CREATE_HOUSEHOLD_SURVEY_ASYNC_STEPS {
  SUBMIT = 'SUBMIT',
  SUBMITTING = 'SUBMITTING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum CREATE_HOUSEHOLD_NEWLETTER_ASYNC_STEPS {
  FORM = 'FORM',
  SUBMITTING = 'SUBMITTING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum EMAIL_SEND_FREQUENCY {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum HOUSEHOLD {
  TRY_FETCH_HOUSEHOLD = 'TRY_FETCH_HOUSEHOLD',
  FETCHING_HOUSEHOLD = 'FETCHING_HOUSEHOLD',
  FETCH_HOUSEHOLD_SUCCESS = 'FETCH_HOUSEHOLD_SUCCESS',
  FETCH_HOUSEHOLD_FAILURE = 'FETCH_HOUSEHOLD_FAILURE',
  FETCH_HOUSEHOLD_NOT_FOUND = 'FETCH_HOUSEHOLD_NOT_FOUND',
  TRY_CREATE_NEW_HOUSEHOLD = 'TRY_CREATE_NEW_HOUSEHOLD',
  CREATING_NEW_HOUSEHOLD = 'CREATING_NEW_HOUSHOLD',
  CREATE_NEW_HOUSEHOLD_SUCCESS = 'CREATE_NEW_HOUSEHOLD_SUCCESS',
  CREATE_NEW_HOUSEHOLD_FAILURE = 'CREATE_NEW_HOUSEHOLD_FAILURE',
}
